import React from "react";

import "./Copyright.css";
function Copyright() {
  return (
    <div className="copyrightBox">
      <p className="copyrightTop">
        Emmet Finance is a frictionless cross-chain (EVM & Non EVM) DeFi Hub
        that gives you access to all the best DeFi opportunities in one place
        securely and safely
      </p>
      <p className="copyright">© 2024 Emmet.Finance, All Rights Reserved.</p>
    </div>
  );
}

export default Copyright;
