import React from "react";

function TransactionDetailsBreadcrumb() {
  return (
    <ul className="breadcum">
      <li>
        <a href="/explorer">Explorer dashboard</a>
      </li>
      <li>Transaction details</li>
    </ul>
  );
}

export default TransactionDetailsBreadcrumb;
